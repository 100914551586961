// export const API_URL = 'https://devserver.lippoinnolab.com';

export const API_DOMAIN = [
  'devserver.lippoinnolab.com',
  'cmsuatapi.lippoinnolab.com',
  'api.healthwaymedical.com.sg',
  'api2.healthwaymedical.com.sg',
  'cmsapi.healthwaymedical.info',
  'localhost:9090',
  'localhost:8991',
];

export const enum DOC_COMP_PARENT {
  'CONSULTATION' = 'CONSULTATION',
  'PATIENT_DETAIL' = 'PATIENT_DETAIL',
  'HISTORY_DETAIL' = 'HISTORY_DETAIL',
}
// export const API_AA_URL = 'https://devserver.lippoinnolab.com/aacore';
// export const API_PATIENT_VISIT_URL = 'https://devserver.lippoinnolab.com/patient-visit';
// export const API_INVENTORY_SYSTEM_URL = 'http://127.0.0.1:8902';
// export const API_PATIENT_INFO_URL = 'https://devserver.lippoinnolab.com/patient-info';
// export const API_CMS_MANAGEMENT_URL = 'https://devserver.lippoinnolab.com/cms-management-proxy';

export const DISPLAY_DATE_FORMAT = 'DD-MM-YYYY';
export const US_DATE_FORMAT = 'MM-DD-YYYY';
export const DISPLAY_DATE_TIME_NO_SECONDS_FORMAT = 'DD-MM-YYYY HH:mm';
export const DISPLAY_DATE_TIME = 'DD-MM-YYYY HH:mm:ss';
export const DISPLAY_TIME_NO_SECONDS_FORMAT = 'hh:mm';
export const DISPLAY_TIME_NO_SECONDS_24_FORMAT = 'HH:mm';
export const DISPLAY_TIME_FORMAT_WIH_SLASHESH = 'DD/MM/YYYY HH:mm:ss';
export const INVENTORY_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_FULL_DATE_FORMAT = 'DD-MM-YYYYTHH:mm:ss';
export const DB_FULL_DATE_FORMAT_LONG = 'DD-MM-YYYYTHH:mm:ss.SSS';
export const DB_FULL_DATE_TIMEZONE_NO_SPACE_Z = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const DB_FULL_DATE_FORMAT_ZERO_SECOND = 'DD-MM-YYYYTHH:mm:00';
export const DB_FULL_DATE_FORMAT_NO_SECOND = 'DD-MM-YYYYTHH:mm';
export const DB_FULL_DATE_TIMEZONE = 'ddd MMM DD YYYY HH:mm:ss ZZ';
export const DB_FULL_DATE_TIMEZONE_NO_SPACE = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const DB_FULL_DATE_TIMEZONE_NO_SPACE_REVERSE = 'DD-MM-YYYYTHH:mm:ss.SSS';
export const DB_FULL_DATE_TIMEZONE_Z = 'YYYY-MM-DDTHH:mm:ssZ';
export const DB_VISIT_DATE_FORMAT = 'DD-MM-YYYYT00:00:00';
export const DB_NEW_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DB_VISIT_DATE_FORMAT_NO_SECOND = 'DD-MM-YYYYT00:00';
export const DISPLAY_DAY_ORDINAL_FORMAT = 'Do MMM YYYY';
export const DISPLAY_DATE_MONTH_FORMAT = 'DD MMM YYYY';

export const INPUT_DELAY = 500;
export const INPUT_DELAY_INVENTORY = 1000;

export const GST_MAP = [
  { cutOffDate: '31-12-2022', gstOnly: 0.07, withGst: 1.07, percentageString: '7' },
  { cutOffDate: '31-12-2023', gstOnly: 0.08, withGst: 1.08, percentageString: '8' },
  { cutOffDate: '31-12-2050', gstOnly: 0.09, withGst: 1.09, percentageString: '9' },
];

export const MEDICAL_COVERAGES = ['INSURANCE', 'CORPORATE', 'CHAS', 'MEDISAVE'];

export const PATIENT_LIST_TABLE_CONFIG = [
  { name: 'Number', prop: 'number', flexGrow: 1 }, // Let display name be #
  { name: 'Visit no', prop: 'visitno', flexGrow: 2 },
  { name: 'Name', flexGrow: 2 },
  { name: 'NRIC', flexGrow: 2 },
  { name: 'Time', flexGrow: 1 },
  { name: 'Doctor', flexGrow: 2 },
  { name: 'Purpose', flexGrow: 3 },
  { name: 'Remarks', flexGrow: 1 },
  { name: 'Status', flexGrow: 1 },
  { name: 'Action', flexGrow: 1 },
  { name: 't', prop: 'patientId', flexGrow: 0 },
  { name: 't', prop: 'patientRegistryId', flexGrow: 0 },
  { name: 't', prop: 'consultationId', flexGrow: 0 },
];

export const INVOICE_LIST_TABLE_CONFIG = [
  { name: 'Date', prop: 'date', flexGrow: 1 }, // Let display name be #
  { name: 'Bill No', prop: 'visitno', flexGrow: 2 },
  { name: 'Clinic', flexGrow: 2 },
  { name: 'NRIC', flexGrow: 2 },
  { name: 'Patient Name', flexGrow: 1 },
  { name: 'Vendor Code', flexGrow: 2 },
  { name: 'Vendor Name', flexGrow: 3 },
  { name: 'Dr Name', flexGrow: 1 },
  { name: 'Service', flexGrow: 1 },
  { name: 'Service Amt', flexGrow: 1 },
];

export const PATIENT_LIST_ENTRY_COUNTS_DROPDOWN = [
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  {
    value: '100',
    label: '100',
  },
];

export const PATIENT_STATUSES = [
  'INITIAL',
  'CONSULT',
  'POST_CONSULT',
  'PAYMENT',
  'COMPLETE',
];
export const STATUS_INITIAL = 'INITIAL';
export const STATUS_CONSULT = 'CONSULT';
export const STATUS_POST_CONSULT = 'POST_CONSULT';
export const STATUS_PAYMENT = 'PAYMENT';
export const STATUS_COMPLETE = 'COMPLETE';

export const PATIENT_QUEUE_STATUS_COLORS = [
  { status: 'INITAL', color: '#8f12fd' },
  { status: 'CONSULT', color: '#f5a523' },
  { status: 'POST_CONSULT', color: '#0094c9' },
  { status: 'PAYMENT', color: '#7ed321' },
  { status: 'COMPLETE', color: '#9b9b9b' },
];

export const PATIENT_STATUSES_DROPDOWN = [
  { label: 'INITIAL', value: 'INITIAL' },
  { label: 'CONSULT', value: 'CONSULT' },
  { label: 'POST CONSULT', value: 'POST_CONSULT' },
  { label: 'PAYMENT', value: 'PAYMENT' },
  { label: 'COMPLETE', value: 'COMPLETE' },
  { label: 'CANCELLED', value: 'CANCELLED' },
];

export const HSG_PATIENT_STATUS_DROPDOWN = [
  { label: 'NEW', value: 'NEW' },
  { label: 'APPOINTMENT MADE', value: 'CONTACTED' },
  { label: 'FIRST VISIT COMPLETED', value: 'FIRST_VISIT_COMPLETED' }
];

export const HSG_PLAN_STATUS_DROPDOWN = [
  { label: 'SUBMIT', value: 'SUBMIT' },
  { label: 'FAILED', value: 'FAILED' },
];

export const ISSUE_TYPE = [
  // { label: 'Note', value: 'NOTE' },
  // { label: 'Problem Long Term', value: 'LONG_TERM' },
  // { label: 'Problem Short Term', value: 'SHORT_TERM' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
];

export const PROBLEM_LIST_STATUS = ['ACTIVE', 'INACTIVE', 'ALL'];

export const PATIENT_LIST_ACTION_LIST_DROPDROWN = [
  'Vital Signs',
  'Update Visit Details',
  'Update Coverage',
  'Print Lab Form',
  'Dispense',
  'Generate Video Link',
  'Cancel',
  'Copy Video Link'
];

export const ALLERGY_TYPES = [
  'SPECIFIC_DRUG',
  'NAME_STARTING_WITH',
  'NAME_CONTAINING',
  'ALLERGY_GROUP',
  'FOOD',
  'OTHER',
  'GENERIC_DRUG',
];

export const DOCTOR_CALENDAR = 'DOCTOR_CALENDAR';
export const CLINIC_CALENDAR = 'CLINIC_CALENDAR';

export const SILVER_CROSS_GROUP = 'Silver Cross Group';

export const DEFAULT_TEMPLATE_CLINIC_NAME = 'HEALTHWAY MEDICAL CLINIC';
export const DEFAULT_TEMPLATE_CLINIC_LETTERHEAD = 'HEALTHWAY MEDICAL';

export const SILVER_CROSS_CLINIC_NAME = 'SILVER CROSS CLINIC';
export const SILVER_CROSS_CLINIC_LETTERHEAD = 'SILVER CROSS';

export const CLINIC_LOGO = new Map([
  ['Silver Cross Group', '/assets/img/hmc_logo.jpg'],
  ['Healthway Medical Group', '/assets/img/new_hmc_logo.png'],
]);

export const ALLERGIES = [
  'nut allergy',
  'egg allergy',
  'other allergy',
  'unknown allergy',
];

export const ALERT_TYPES = [
  'MEDICAL_ALERT',
  'CHRONIC_DISEASE',
  'MEDICAL_CONDITION',
  'MEDICATION',
  'ALLERGY',
  'REMINDERS',
  'OTHER',
];

export const ALERT_PRIORITY = ['HIGH', 'LOW'];

export const MC_REASONS = [
  'UNFIT FOR DUTY',
  'UNFIT FOR ICT',
  'UNFIT FOR SCHOOL',
  'EXCUSED SHOES/SOCKS',
  'UNFIT FOR PHYSICAL EXERCISE',
  'EXCUSED LOWER LIMB ACTIVITIES',
  'LIGHT DUTIES ONLY',
  'FIT FOR DUTY',
  'UNFIT FOR IPPT',
  'FIT FOR SCHOOL',
  'UNFIT FOR REMEDIAL TRAINING',
  'HOSPITALISATION LEAVE',
  'MATERNITY LEAVE',
  'OUTPATIENT SICK LEAVE',
  'UNFIT FOR PHYSICAL ACTIVITIES',
  'UNFIT FOR CCA',
  'UNFIT FOR TRAVEL',
  'OTHERS',
];

export const MC_REASONS_DROPDOWN = [
  { label: 'UNFIT FOR DUTY', value: 'UNFIT FOR DUTY' },
  { label: 'UNFIT FOR ICT', value: 'UNFIT FOR ICT' },
  { label: 'UNFIT FOR SCHOOL', value: 'UNFIT FOR SCHOOL' },
  { label: 'UNFIT (Mandatory)', value: 'UNFIT (Mandatory)' },
  { label: 'EXCUSED SHOES/SOCKS', value: 'EXCUSED SHOES/SOCKS' },
  {
    label: 'UNFIT FOR PHYSICAL EXERCISE',
    value: 'UNFIT FOR PHYSICAL EXERCISE',
  },
  { label: 'UNFIT FOR CCA', value: 'UNFIT FOR CCA' },
  { label: 'UNFIT FOR TRAVEL', value: 'UNFIT FOR TRAVEL' },
  {
    label: 'EXCUSED LOWER LIMB ACTIVITIES',
    value: 'EXCUSED LOWER LIMB ACTIVITIES',
  },
  { label: 'LIGHT DUTIES ONLY', value: 'LIGHT DUTIES ONLY' },
  { label: 'FIT FOR DUTY', value: 'FIT FOR DUTY' },
  { label: 'UNFIT FOR IPPT', value: 'UNFIT FOR IPPT' },
  { label: 'FIT FOR SCHOOL', value: 'FIT FOR SCHOOL' },
  {
    label: 'UNFIT FOR REMEDIAL TRAINING',
    value: 'UNFIT FOR REMEDIAL TRAINING',
  },
  { label: 'HOSPITALISATION LEAVE', value: 'HOSPITALISATION LEAVE' },
  { label: 'MATERNITY LEAVE', value: 'MATERNITY LEAVE' },
  { label: 'OUTPATIENT SICK LEAVE', value: 'OUTPATIENT SICK LEAVE' },
  {
    label: 'UNFIT FOR PHYSICAL ACTIVITIES',
    value: 'UNFIT FOR PHYSICAL ACTIVITIES',
  },
  { label: 'OTHERS', value: 'OTHERS' },
];

export const MC_HALFDAY_OPTIONS = [
  { label: 'AM - Last Day', value: 'AM_LAST' },
  { label: 'PM - First Day', value: 'PM_FIRST' },
];

export const HEADER_TITLES = [
  { url: '/pages/patient/list', value: 'Patient Registry' },
  { url: '/pages/patient', value: 'Patient Registry' },
  { url: '/pages/dashboard', value: 'Dashboard' },
  { url: '/pages/appointments', value: 'Appointments' },
  { url: '/pages/appointments/overview', value: 'Appointments' },
  { url: '/pages/consultation/add', value: 'Consultation' },
  { url: '/pages/patient/search', value: 'Patients / Search Patients' },
  { url: '/pages/patient/detail', value: 'Patient Particulars' },
  { url: '/pages/patient/detail?tabIndex=1', value: 'Patient Particulars' },
  { url: '/pages/patient/add', value: 'Add New Patient' },
  { url: '/pages/payment/charge', value: 'Collect Payment' },
  { url: '/pages/payment/collect', value: 'Collect Payment' },
  { url: '/pages/communications/main/follow-ups', value: 'Communications' },
  { url: '/pages/communications', value: 'Communications' },
  { url: '/pages/communications/online-doctor', value: 'Online Doctor' },
  { url: '/pages/claim/claim-home', value: 'Claims' },
  { url: '/pages/claim/manual-claim', value: 'Manual Claims' },
  { url: '/pages/claim/claim-submission', value: 'Submission Record' },
  { url: '/pages/claim/create-payment', value: 'Payment Record' },
  { url: '/pages/claim/mhcp-claim', value: 'MHCP Claims' },
  { url: '/pages/claim/dental-claim', value: 'Dental Claims' },
  { url: '/pages/claim/medisave-claim', value: 'Medisave Claims' },
  { url: '/pages/claim/sfl-claim', value: 'SFL Claims' },
  { url: '/pages/claim/refund', value: 'Refunds' },
  { url: '/pages/claim/bill-adjustment', value: 'Bill Adjustment' },
  { url: '/pages/claim/claim-sfl', value: 'SFL' },
  { url: '/pages/report', value: 'Reports' },
  { url: '/pages/report/search', value: 'Reports' },
  { url: '/pages/inventory/main', value: 'Inventory' },
  { url: '/pages/inventory/detail/', value: 'Requisitions Detail' },
  {
    url: '/pages/inventory/purchase-request',
    value: 'Purchase Request (Standard)',
  },
  {
    url: '/pages/inventory/purchase-request;special=true',
    value: 'Purchase Request (Loose)',
  },
  {
    url: '/pages/inventory/purchase-request;reorder=true',
    value: 'Re-order Low Stock',
  },
  { url: '/pages/inventory/return-request/', value: 'Return Request' },
  {
    url: '/pages/inventory/return-request-detail/',
    value: 'Return Request Detail',
  },
  { url: '/pages/case/list', value: 'Case Manager' },
  { url: '/pages/case/detail', value: 'Case Details' },
  { url: '/pages/settings', value: 'Settings' },
  { url: '/pages/settings/templates', value: 'Settings' },
  { url: '/pages/cdm/list/pcn', value: 'Primary Care Network' },
  { url: '/pages/cdm/list/cidc', value: 'CIDC' },
  { url: '/pages/projects/nhpslist', value: 'National Population Health Survey (NPHS)' },
  { url: '/pages/projects/nirlist', value: 'Submission to NIR' },
  { url: '/pages/projects/list', value: 'Projects' },
  { url: '/pages/invoice', value: 'Invoice Management' },
  { url: '/pages/invoice/list', value: 'Invoice Management' },
  { url: '/pages/invoice/search', value: 'Search Invoice' },
  { url: '/pages/invoice/verify', value: 'Verify Invoices' },
  { url: '/pages/notes/list', value: 'User Notes' },

  { url: '/pages/projects/hwmedlist', value: 'MWHealth' },
  { url: '/pages/projects/mwoc', value: 'MWOC Quick Actions' },
  { url: '/pages/cdlens/list', value: 'CDLENS Communicable Disease Live & Enhanced Notification System- via IRIS' },

  { url: '/pages/projects/hsg/dashboard', value: 'Healthier SG' },
  { url: '/pages/projects/hsg/list/patient_list', value: 'Healthier SG' },
  { url: '/pages/projects/hsg/list/plan_list', value: 'Healthier SG' },
  { url: '/pages/projects/hsg/care_report_generator', value: 'Healthier SG' },
  { url: '/pages/projects/hsg/list', value: 'Healthier SG' },
  { url: '/pages/projects/hsg/list/plan', value: 'Healthier SG' },
  { url: '/pages/projects/hsg/patient_list', value: 'Healthier SG' },

  { url: '/pages/projects/remote-delivery-tracking', value: 'Remote Delivery and Tracking' },
];
export const PRIVATE_PATIENT_REPORTS = [{
  "reportDisplayName": "Normal",
  "reportName": 'private_corporate_patient_listing'

},
{
  "reportDisplayName": "Paginated",
  "reportName": 'private_corporate_patient_listing_paginated'

},
{
  "reportDisplayName": "Short",
  "reportName": 'private_corporate_patient_listing_short'

}
];

export const REPORT_TYPES = [  
  { value: false, label: 'Normal' },
  { value: true, label: 'Details' }
];
export const LOCK_NOTES_DROPDOWN = [
  {
    short_label: 'Lock for CA',
    long_label: 'Lock for CA',
    value: 'LOCK_FOR_CA',
  },
  {
    short_label: 'Lock for All',
    long_label: 'Lock for All',
    value: 'LOCK_FOR_ALL',
  },
  { short_label: 'Open', long_label: 'Open', value: 'OPEN' },
  { short_label: 'Lock excluding clinic', long_label: 'Lock excluding clinic', value: 'LOCK_EXCLUDING_CLINIC' },
];

export const PATIENT_INFO_KEYS = [
  'title',
  'preferredMethodOfCommunication',
  'consentGiven',
  'marketingOptOut',
  'race',
  'preferredLanguage',
  'name',
  'dob',
  'userId',
  'gender',
  'contactNumber',
  'secondaryNumber',
  'status',
  'address',
  'emailAddress',
  'emergencyContactNumber',
  'company',
  'nationality',
  'maritalStatus',
  'allergies',
  'patientVaccinations',
  'primaryCareNetwork',
  'relationshipEntities',
  'patientNewBorn',
  'savedFromAllergyTab',
  'patientIdentifier',
  'additionalIdentifier'
];

export const DAY_OF_WEEK = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const DAY_INT_OF_WEEK = [
  { MONDAY: 1 },
  { TUESDAY: 2 },
  { WEDNESDAY: 3 },
  { THURSDAY: 4 },
  { FRIDAY: 5 },
  { SATURDAY: 6 },
  { SUNDAY: 7 },
];

export const CLINIC_NOTES_TITLE = 'Notes to Clinic Staff';
export const CONS_NOTES_TITLE = 'Diagnosis / Notes';
export const CASH = 'Cash';

export const ITEM_DRUG = 'DRUG';
export const ITEM_CONSULTATION = 'CONSULTATION';
export const ITEM_MEDICAL_SERVICE = 'SERVICE';
export const ITEM_LABORATORY = 'LABORATORY';
export const ITEM_VACCINATION = 'VACCINATION';

export const APPOINTMENT_TYPE = [
  'APPOINTMENT',
  'CLINIC_HOLIDAY',
  'DOCTOR_BLOCKED_TIME',
  'DOCTOR_LEAVE',
];

export const TABS = {
  PROFILE: 'Profile',
  VITALS: 'Patient Indicators',
  ASSESSMENTS: 'Assessments',
  HISTORY: 'History',
  CONSULTATION: 'Consultation',
  PCN: 'CDM',
  HSG: 'HSG',
  SERVICES: 'Medical Services',
  COVERAGE: 'Coverage',
  DOCUMENTS: 'Documents',
  DISPENSING: 'Dispensing',
  PRINTING: 'Printing',
  PAYMENT: 'Payment',
  DENTAL: 'Dental Charting',
  SURVEY: 'Survey'
};

export enum PCNFORMS {
  PATIENTEXTRADETAILSFORM = 'patientExtraDetailsForm',
  PATIENTCONDITIONFORM = 'patientConditionForm',
  COMPLICATIONFORM = 'complicationForm',
  VITALFORM = 'vitalForm',
  ASSESSMENTFORM = 'assessmentForm',
  MEDICATIONFORM = 'medicationForm'
}

export enum CDLENSFORMS {
  PATIENTINFOFORM = 'patientInfoForm',
  CLINICDOCTORDETAILSFORM = 'clinicDoctorDetailsForm',
  DISEASEDIAGNOSISDETAILSFORM = 'diseaseDiagnosisDetailsForm',
  TRAVELHISTORYFORM = 'travelHistoryForm',
  TBPATIENTINFOFORM = 'tbPatientInfoForm',
  CDLENSPATIENTINFOFORM = 'cdlensPatientInfoForm',
  TBDIAGNOSISDETAILSFORM = 'tbDiagnosisDetailsForm',
  CHEMOTHERAPYDETAILSFORM= 'chemotherapyInfoDetailsForm'
}

export enum HSGFORMS {
  PATIENTEXTRADETAILSFORM = 'patientExtraDetailsForm',
  PATIENTCONDITIONFORM = 'patientConditionForm',
  COMPLICATIONFORM = 'complicationForm',
  VITALFORM = 'vitalForm',
  ASSESSMENTFORM = 'assessmentForm',
  VACCINATIONS = 'vaccinations',
  SFLSCREENING = 'sflScreening',
  GOALFORM = 'goalForm',
}

export const DOCTOR_BLOCKED_TIME = 'DOCTOR_BLOCKED_TIME';
export const CLINIC_BLOCKED_TIME = 'CLINIC_BLOCKED_TIME';
export const DOCTOR_LEAVE = 'DOCTOR_LEAVE';
export const CLINIC_HOLIDAY = 'CLINIC_HOLIDAY';
export const APPOINTMENT = 'APPOINTMENT';
export const SERVICE_BLOCKED_TIME = 'SERVICE_BLOCKED_TIME';

export const CONFIRM_DOUBLE_REGISTRATION =
  'This patient has already registered a visit in the registry. Do you still want to proceed?';

export const CANCELLED_VISIT =
  'This patient cancelled the visit.';



// Inventory Invalid Msgs
export const SEPARATE_BATCH_AVAILABLE =
  'Items are available in different batches. Please issue item separately.';
// export const SEPARATE_BATCH_AVAILABLE = 'Input quantity exceeds batch stock. Please add separate batch.';

export const INSUFFICIENT_STOCK = 'Stock may not be sufficient.';
export const UNAVAILABLE_INVENTORY =
  'This item is not available in the inventory.';

export const CHAS_BALANCE_UNAVAILABLE =
  "CHAS balance can't be retrieved. Please check with the portal.";
export const BALANCE_UNAVAILABLE =
  'Balance is unavailable. Please check with the portal.';
export const ITEM_ONLY_AVAILABLE_FOR_HSG_PATIENT = "This item is only available for HSG patients.";
export const ITEM_ONLY_AVAILABLE_FOR_HSG_COVERAGES = "This item is only available for HSG Coverages.";
export const ITEM_ADJUSTED_AMOUNT_SHOULD_BE_BELOW = (value: string) => `Adjusted amount should be below ${value}`;
export const ITEM_UOM_IS_NOT_CONFIGURED = (uom: string) =>  `UOM ${uom} is not configured for this item.`;

export const PCN_CONDITIONS = [
  { name: 'Pre-Diabetes', selected: false, id: 1 },
  { name: 'Diabetes', selected: false, id: 2 },
  { name: 'Hypertension', selected: false, id: 3 },
  { name: 'Lipid Disorders', selected: false, id: 4 },
  { name: 'Stroke', selected: false, id: 5 },
  { name: 'Dementia', selected: false, id: 2 },
  { name: 'Asthma', selected: false, id: 6 },
  { name: 'COPD', selected: false, id: 7 },
  { name: 'Osteoarthritis', selected: false, id: 8 },
  { name: 'Benign Prostatic Hyperplasia', selected: false, id: 9 },
  { name: 'Nephritis / Nephrosis', selected: false, id: 10 },
  { name: "Parkinson's Disease", selected: false, id: 11 },
  { name: 'Epilepsy', selected: false, id: 12 },
  { name: 'Osteoporosis', selected: false, id: 13 },
  { name: 'Psoriasis', selected: false, id: 14 },
  { name: 'Rheumatoid arthritis', selected: false, id: 15 },
];

export const PCN_CHAS_STATUS = [
  'CHAS Blue',
  'CHAS Orange',
  'CHAS Green',
  'MG',
  'PA',
  'PG',
  'Non-CHAS',
];

export const DOCTOR_BLOCK_TYPE = [
  { name: 'Leave', value: 'AL' },
  { name: 'Blocked Time', value: 'BL' },
];

export const PCN_MEDISAVE_USAGE = [
  {
    value: true,
    label: 'YES',
  },
  { value: false, label: 'NO' },
];

export const PCN_REFERENCE_SOURCE = [
  'Self',
  'Polyclinic',
  'Private Clinics/Hospitals',
  'PHIs/SOCs',
  'FMCs',
];

export const DATA_SAVED_SUCCESSFULLY = 'Data Saved Successfully.';

export const COVERAGE_MODE = {
  ADD_TO_REGISTRY: 'ADD_TO_REGISTRY',
  ATTACH_MEDICAL_COVERAGE: 'ATTACH_MEDICAL_COVERAGE',
  DISPLAY_MEDICAL_COVERAGE: 'DISPLAY_MEDICAL_COVERAGE',
  REGISTER_PATIENT_ONLY: 'REGISTER_PATIENT_ONLY',
};

export const colors: any = {
  red: {
    primary: '#EFC7C2',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#FFE5D4',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#BFD3C1',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#E0D3DE',
    secondary: '#FDF1BA',
  },
  black: {
    primary: '#FFFFFF',
    secondary: '#000000',
  },
};

export const doctorColorCode: any = [


  '#A4B494',
  '#4B3F72',
  '#A06CD5',
  '#E9D2C0',
  '#417B5A',
  '#D0CEBA',
  '#90AA86',
  '#417B5A',
  '#4A2545',
  '#8B575C',
  '#C98986',
  '#F6BDD1',
  '#87D68D',
  '#93B48B',
  '#8491A3',
  '#9FA4A9',
  '#847E89',
  '#AFBFC0',
  '#273C2C',
  '#626868',
  '#939196',
  '#9FA4A9',
  '#D3C1D2',
  '#FFE2FE',
  '#EAD2AC',
  '#DF928E',
  '#C58882',
  '#D1DEDE',
  '#BEBBBB',
  '#DB8A74',
  '#E3B23C',
  '#8FC93A',
  '#1E91D6',
  '#A06CD5',
  '#FFA69E',
  '#FFEECF',
];

export const locumColorCode = '#3949ab';
export const noPreferredDoctorColorCode = '#4D4861';

export const EMERGENCY_CONTACT_RELATIONSHIPS = [
  { value: 'SPOUSE', label: 'SPOUSE' },
  { value: 'FATHER', label: 'FATHER' },
  { value: 'MOTHER', label: 'MOTHER' },
  { value: 'PARENT', label: 'PARENT' },
  { value: 'CHILDREN', label: 'CHILDREN' },
  { value: 'IN_LAWS', label: 'IN LAWS' },
  { value: 'GRANDPARENT', label: 'GRANDPARENT' },
  { value: 'FRIEND', label: 'FRIEND' },
  { value: 'RELATIVE', label: 'RELATIVE' },
  { value: 'OTHER', label: 'OTHER' },
];

export const TOASTR_TIMEOUT = 3000;
export const DEFAULT_DOC_COLOR = "#034D0A";

export const MEDICAL_ALERT_STATUS = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'IN-ACTIVE' }
];

export const REACTION_TYPES = [
  { value: 'ALLERGY', label: 'Allergy' },
  { value: 'ADVERSE_DRUG_REACTION', label: 'Adverse Drug Reaction' }
];

export const ADMINISTRATOR_TYPES =
  [
    { name: 'Dentist', value: 'DENTIST' },
    { name: 'Doctor', value: 'DOCTOR' },
    { name: 'Pharmacist', value: 'PHARMACIST' },
    { name: 'Nurse', value: 'NURSE' },
  ];

export const SFL_TEST_ORDER = [
  { value: 1, label: 'Initial Screen' },
  { value: 2, label: '1st Repeat Screen' },
  { value: 3, label: '2nd Repeat Screen' },
  { value: 4, label: 'Follow Up' },
];


export const RERERRED_TO_INSTITUTION = [
  { value: 'Poly', label: 'Polyclinic' },
  { value: 'SGH', label: 'SGH Fever Screening Area' },
  { value: 'NCID', label: 'NCID Screening Centre' },
  { value: 'GP', label: 'SASH PHPC' },
  { value: 'RSC', label: 'Regional Screening Centre' }
];

export const TRUE_FALSE_ANWS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];


export const SMOKING_STATUS = [
  { value: 'SMOKER', label: 'Smoker' },
  { value: 'EX_SMOKER', label: 'Ex Smoker' },
  { value: 'NON_SMOKER', label: 'Non Smoker' }
];

export const SMOKING_STATE_OF_CHANGE = [
  { value: 'PRE_CONTEMPLATION', label: 'Pre Contemplation' },
  { value: 'CONTEMPLATION', label: 'Contemplation' },
  { value: 'PREPARATION', label: 'Preparation' },
  { value: 'ACTION', label: 'Action' },
  { value: 'MAINTENANCE', label: 'Maintenance' }
];

export const DATE_ASSESSMENT_CODES: string[] = [
  'eyeTestDate',
  'footTestDate',
  'GINATestDate',
  'NURSE_COUNSELLING_DATE',
  'SPIROMETRY_DATE',
  'WAAP_DATE',
  'ASTHMA_COUNSELLING_DATE',
  'COPD_COUNSELLING_DATE',
  'SERUM_URIC_ACID_DATE',
  'REGULAR_PREVENTER_REVIEW_DATE',
  'RESCUE_THERAPY_DATE',
  'CKD_STAGES_DATE',
  'ACEI_ARB_DATE',
  'SGLT_2_DATE',
  'ANTIPLATELET_THERAPY_DATE',
  'BETA_BLOCKER_DATE',
  'HBAIC_DATE',
  'FPG_DATE',
  'OGTT_DATE',
  'LDL_C_DATE',
  'HDL_C_DATE',
  'TRIGLYCERIDES_DATE',
  'TOTAL_CHOLESTEROL_DATE',
  'SERUM_CREATININE_DATE',
  'EFGR_DATE',
  'UACR_DATE',
  'uPCR_DATE',
  'ASSESS_COPD_DATE',
  'weight-height-date',
  'bmi-date',
  'blood-pressure-date'
];

export const YES_NO_ASSESS_MAPPING = {
  'REGULAR_PREVENTER_REVIEW': 'REGULAR_PREVENTER_REVIEW_DATE',
  'RESCUE_THERAPY': 'RESCUE_THERAPY_DATE',
  'ACEI_ARB': 'ACEI_ARB_DATE',
  'SGLT_2': 'SGLT_2_DATE',
  'ANTIPLATELET_THERAPY': 'ANTIPLATELET_THERAPY_DATE',
  'BETA_BLOCKER': 'BETA_BLOCKER_DATE'
};

export const INPUT_ASSESS_MAPPING = {
  'HBAIC': 'HBAIC_DATE',
  'FPG': 'FPG_DATE',
  'OGTT': 'OGTT_DATE',
  'LDL_C': 'LDL_C_DATE',
  'HDL_C': 'HDL_C_DATE',
  'TRIGLYCERIDES': 'TRIGLYCERIDES_DATE',
  'TOTAL_CHOLESTEROL': 'TOTAL_CHOLESTEROL_DATE',
  'SERUM_URIC_ACID': 'SERUM_URIC_ACID_DATE',
  'GINA_S': 'GINATestDate',
  'SERUM_CREATININE': 'SERUM_CREATININE_DATE',
  'EFGR': 'EFGR_DATE',
  'UACR': 'UACR_DATE',
  'uPCR': 'uPCR_DATE',
  'ASSESS_COPD': 'ASSESS_COPD_DATE'
};

export const MULTI_SELECT_ASSESSMENT_CODES: string[] = [
  'DRP_RESULT',
];

export const ID_TYPES = [
  { value: 'NRIC_PINK', label: 'NRIC (Pink)' },
  { value: 'NRIC_BLUE', label: 'NRIC (Blue)' },
  { value: 'MIC', label: 'Malaysian IC' },
  { value: 'FIN', label: 'FIN' },
  { value: 'PASSPORT', label: 'Passport' },
  { value: 'BIRTH_CERTIFICATE', label: 'Birth Certificate' },
  { value: 'OTHER', label: 'Other' },
]
