<div class="row" style="background-color: #f5f7fa" class="mx-1">
    <div class="col-md ml-2 py-2" (click)="onRowClicked()">
        <i [ngClass]="isCollapsed ? 'icon-right-open-big mr-2':'icon-down-open-big mr-2'"></i>
    </div>
</div>

<form [formGroup]='referralItem' class="px-3" *ngIf="!isCollapsed">
    <!-- <pre>{{ referralItem.value | json }}</pre> -->
    <div class="row mb-2">

        <!-- SPECIALITY -->
        <div class="col-md-2">

            <label class="mb-0">Speciality</label>
            <ng-select
            class="p-0 form-control form-control-sm custom-select custom-select-extra-medium-width"
            [items]="practices"
            [clearable]=false
            bindLabel="speciality" bindValue="speciality"
            [virtualScroll]="true"
            placeholder="Select Speciality"
            formControlName="practice"
            (change)="onPracticeSelected($event)"
            >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <p [ngClass]="(item.types.includes('INTERNAL') || item.types.includes('PARTNER')) ? 'internal-partner' : 'external-hospital' ">
                    {{item?.speciality}}
                </p>
              </ng-template>
            </ng-select>

            <p style="font-size: 13px; float: left; margin: 0px; padding: 0px;">* if highlighted , there is availability for Internal/Partner referral options for the specified speciality.</p>
            <br>
            <p class="error" *ngIf="isPracticeEmpty">Please select speciality to continue</p>
            <!-- <errors [control]="referralItem.get('practice')"></errors> -->
        </div>

        <!-- REFERRAL TYPE -->
        <div  class="col-md-2" *ngIf="referralItem.get('practice').value=='Other'" >
            <label class="mb-0">Enter Speciality</label>
            <input type="text" formControlName="practiceO" class="form-control form-control-sm" placeholder="Type speciality">
            <errors [control]="referralItem.get('practiceO')"></errors>
        </div>
        <div  class="col-md-2">
            <label class="mb-0">Referral Type</label>
            <ng-select
                class="p-0 form-control form-control-sm  custom-select"
                [items]="referralTypes"
                [clearable]=false
                [virtualScroll]="true"
                formControlName="referralType"
                bindValue='value'
                (change)="onReferralTypeSelected($event)"
                [disabled]="inPaymentMode()">
            </ng-select>
            <!-- <errors [control]="referralItem.get('practice')"></errors> -->
        </div>

        <div class="col-md-2" [formGroup]="referralItem.get('externalReferralDetails')" *ngIf="isExternalReferralType">
            <label class="mb-0">External Referral Type</label>
            <ng-select class="p-0 form-control form-control-sm  custom-select" [items]="externalReferralTypes"
                [clearable]=true [virtualScroll]="true" bindValue='value' formControlName="externalReferralType">
            </ng-select>
        </div>

        <!-- Doction filter -->
        <div *ngIf="!isPaymentMode" class="col-md-2">
            <label class="mb-0">Doctor/Institute</label>
            <ng-select class="p-0 form-control form-control-sm custom-select custom-select-extra-medium-width"
                [items]="doctorFilterOptions" [virtualScroll]="true" bindLabel='name' bindValue='id'
                [clearable]=true [formControl]="doctorFilter">
            </ng-select>
        </div>

        <!-- Clinic filter -->
        <!-- <div class="col-md-2">
            <span *ngIf="!isPaymentMode">
                <label class="mb-0">Clinic Filter</label>
                <ng-select class="p-0 form-control form-control-sm custom-select custom-select-extra-medium-width" [items]="clinicFilterOptions" [virtualScroll]="true" bindLabel='name' bindValue='id' [clearable]=true [formControl]="clinicFilter">
                </ng-select>
            </span>
        </div> -->

        <div *ngIf="isPaymentMode; else editRequiredDoctorName;" class="col-md-3" [formGroup]="referralItem.get('externalReferralDetails')">
            <label class="mb-0 ">Doctor's/Institue's Name</label>
            <input type="text" class="form-control form-control-sm" formControlName="doctorName">

                <errors [control]="referralItem.get('externalReferralDetails').get('doctorName')"></errors>

        </div>

        <ng-template #editRequiredDoctorName>
            <div class="col-md-2" [formGroup]="referralItem.get('externalReferralDetails')">
                <label class="mb-0 ">Doctor's/Institue's Name</label>
                <input type="text" class="form-control form-control-sm" formControlName="doctorName">
                <p style="font-size: 10px;" class="error" *ngIf="isNotFive()">Minumum 5 Charachters </p>
            </div>
        </ng-template>

        <div *ngIf="isPaymentMode; else editRequiredAddress;" class="col-md-3" [formGroup]="referralItem.get('externalReferralDetails')">
            <div class="row">
                <div class="col-md-10">
                    <label class="mb-0 ">Address</label>
                    <input type="text " class="form-control form-control-sm" formControlName="address">
                    <errors [control]="referralItem.get('externalReferralDetails').get('address')"></errors>
                </div>
                <div class="col-md-2 pt-3 d-flex align-items-center" *ngIf="!isPaymentMode">
                    <button class="btn btn-link-grey py-1 px-0" (click)="onbtnDeleteClicked()">
                        <i class="icon-trash"></i>
                    </button>
                </div>
            </div>
        </div>

        <ng-template #editRequiredAddress>
            <div class="col-md-2" [formGroup]="referralItem.get('externalReferralDetails')">
                <div class="row">
                    <div class="col-md-10">
                        <label class="mb-0 ">Address</label>
                        <input type="text " class="form-control form-control-sm" formControlName="address">
                        <errors [control]="referralItem.get('externalReferralDetails').get('address')"></errors>
                    </div>
                    <div class="col-md-2 pt-3 d-flex align-items-center" *ngIf="!isPaymentMode">
                        <button class="btn btn-link-grey py-1 px-0" (click)="onbtnDeleteClicked()">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>
    <!-- <div *ngIf="!isPaymentMode" class="col-md-1 pt-2 align-self-center">
      <div class="row no-gutters">
        <label class="mb-0 "></label>
        <i class="icon-ellipsis-vert" [popover]="popTemplate2" placement="top" [outsideClick]="true"></i>
      </div>
    </div> -->
    <div>
        <p class="error" *ngIf="isHositalType">&#42;For HSG suggested Public Hospital referrals will have Sub/Expanded Specialist under "Panel Doctor" column, please verify if the referral purpose is covered under one of the categories.</p>
        <p class="error" *ngIf="referralItem.get('externalReferralDetails').get('externalReferralType').value === 'GP First'" style="font-weight: 500;"><i>Please select your partner A&E/UCC for GPFirst referral. If 995 is activated, the patient will be sent to the nearest appropriate A&E based on SCDF conveyance protocols.</i></p>
        <p class="error" *ngIf="referralItem.get('externalReferralDetails').get('externalReferralType').value === 'A&E/UCC'" style="font-weight: 500;"><i>If 995 is activated, the patient will be sent to the nearest appropriate A&E based on SCDF conveyance protocols.</i></p>
    </div>
    <hr>
    <div class="row" *ngIf="shouldShowSelectDoctorTable && !isPaymentMode">
        <div class="col-md-1">
            <label class="mb-0 ">Select Doctor</label>
        </div>
    </div>
    <div class="row mb-2" *ngIf="shouldShowSelectDoctorTable && !isPaymentMode">
        <div class="col-md">
            <ngx-datatable #table class='bootstrap referal-item' [columnMode]="'flex'" [headerHeight]="30"
                [footerHeight]="30" [rowHeight]="auto" [rows]='doctorOptions' [selectionType]="'single'"
                [selected]="selectedDoctor" (select)='onDoctorSelected($event)'>
                <ngx-datatable-column name="Selected" prop="selected" [resizeable]=false [flexGrow]=1.5 [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <input class="ml-3" type="radio" [checked]="isDoctorItemSelected(row.id, row.locationId)"
                            [disabled]="isPaymentMode">
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Dr Name" prop="name" [resizeable]=false [flexGrow]=3 [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <div>{{ row.name }}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Speciality" prop="speciality" [resizeable]=false [flexGrow]=4 [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <div>{{ row.speciality }}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Medical Institute Code" prop="code" [resizeable]=false [flexGrow]=3 [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <div>{{ row.code }}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Address" prop="address" [resizeable]=false [flexGrow]=6 [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <div>{{ row.address }}</div>
                        <div *ngIf="row.phone">Tel:{{ row.phone }}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Panel Doctor" prop="panel" [resizeable]=false [flexGrow]=3 *ngIf="false" [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <div></div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Type" prop="type" [resizeable]=false [flexGrow]=2 [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <div>{{ row.type }}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Panel Doctor/Expanded Specialist" prop="panels" [resizeable]=false [flexGrow]=6 [cellClass]="getCellClass">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                        <div *ngIf="!showFullText[rowIndex]">{{ truncate(row.panels, 150) }} 
                            <button *ngIf="row.panels?.length > 150" class="btn btn-link-peacock-blue py-1 px-0" (click)="showOptionToggle($event, rowIndex, true)">more</button>
                        </div>
                        <div *ngIf="showFullText[rowIndex]">
                            {{ truncate(row.panels, row.panels?.length) }}
                            <button class="btn btn-link-peacock-blue py-1 px-0" (click)="showOptionToggle($event, rowIndex)">less</button>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <errors [control]="referralItem.get('selectedDoctorRequired')"></errors>
            <!-- <errors [control]="selectedDoctorRequired"></errors> -->
        </div>
    </div>

    <hr *ngIf="shouldShowSelectDoctorTable && !isPaymentMode">

    <div class="row align-items-center mb-2" *ngIf="referralItem.get('referralType').value === 'INTERNAL'">

        <div class="col-md" [formGroup]="referralItem.get('externalReferralDetails')">
            <!-- {{ referralItem.get('externalReferralDetails').value | json }} -->
            <div class="row">

                <div class='col-md-2'>
                    <app-date-picker-with-icon [config]="datePickerConfig" [dateControl]="appointmentDate">
                    </app-date-picker-with-icon>
                    <errors [control]="appointmentDate"></errors>
                </div>
                <div class='col-md-2'>
                    <!-- <div> -->
                    <label></label>
                    <timepicker class="timepicker-report" [showMeridian]="true" [showSpinners]="false"
                        [formControl]='appointmentTime'>
                    </timepicker>
                    <errors [control]="appointmentTime"></errors>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 ">
            <!-- <label class="mb-0 ">Remarks</label> -->
            <label class="mb-0 ">Dear
                {{ referralItem.get('externalReferralDetails').get('doctorName').value }}</label>
            <!-- <textarea cols="10 " rows="5 " class="form-control fomr-control-sm " formControlName="memo"></textarea> -->
            <!-- <app-ckeditor [control]="referralItem.get('memo')" [disabled]="isPaymentMode">
            <p>Hello
                <strong>world</strong>
            </p>
            </app-ckeditor> -->
            <app-rich-editor-switcher [control]="referralItem.get('memo')" [disabled]="!(!isPaymentMode || editMode)">
            </app-rich-editor-switcher>
            <!-- <app-read-only *ngIf="isPaymentMode" [innerHTMLMode]=true [notes]="referralItem.get('memo')" [title]="title"></app-read-only> -->
        </div>
        <errors [control]="referralItem.get('memo')"></errors>
    </div>
    <div class="row ">
        <div class="col-md-12 ">
            <span class="float-right"><label class="mb-0 ">Kind Regards</label></span>
        </div>
    </div>
    <!-- TEMPLATE -->
    <div [hidden]="isTemplateHidden" class="row">
        <div class="col" *ngIf="!editMode || (editMode && isNew)">
            <p class="error" *ngIf="isExternalReferralType"> &#42; Please use the Related HSG Referral template Since an External Referral Type has been selected. Take note NOT to modify the template and only to fill the required areas.</p>
            <label class="mb-0">Template</label>
            <ng-select class="form-control  form-control-sm custom-select p-0" bindLabel="name" bindValue="id"
                groupBy="type" [items]="templates" [virtualScroll]="true" (change)="onTemplateChange($event)"
                [(ngModel)]="template" [ngModelOptions]="{standalone: true}" placeholder="Select Template"></ng-select>
        </div>
    </div>
    <hr>
</form>
